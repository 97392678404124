<div class="split">
  <div class="start fx-row justify-end">
    <div class="fx-col justify-end align-end">
      <img class="eye" src="assets/images/page/plan/doctor_eye_w.png" alt="" />
      <p class="no-margin">璞学至慧官方服务号 / ID: fliprobot01</p>
      <p class="h5 no-margin">进一步了解立即扫码加微信</p>
    </div>
    <img
      src="assets/images/page/plan/doctor_qrcode.png"
      alt=""
      class="qrcode"
    />
  </div>
  <div class="end fx-col justify-end">
    <span class="h5 form">我有意愿加盟，点此填表</span>
    <p class="h5 no-margin">加盟专线 | 微信洽谈</p>
  </div>
</div>
