<section class="banner bg fx-center fx-col" style="padding: 0 20px">
  <h1 class="no-margin font-bold text-white">学校导入方案</h1>
  <p class="h5 no-margin font-bold text-white">翻转机器人（FlipRobot）</p>
  <p class="h5 no-margin font-bold text-white" style="padding: 0 30px">
    提供学校发展STEAM课程最丰富的全球教育资源。
  </p>
</section>
<section class="split-reverse">
  <div class="start">
    <picture>
      <source
        media="(max-width: 600px)"
        srcset="assets/images/page/plan/school/2_mobile.jpg"
      />
      <img
        class="img-fluid"
        src="assets/images/page/plan/school/2.jpg"
        alt=""
      />
    </picture>
  </div>
  <div class="end" style="padding: 30px">
    <h3 style="font-weight: 500">
      完整STEAM系统学程课<br />
      创造校园领先趋势！
    </h3>
    <p style="max-width: 600px">
      「翻转机器人」是FlipRobot教育的学校推广品牌，
      我们提供国际学校、民办学校及公立学校完整的STEAM教育方案。
      我们由厚实的教育学术为基础，打造由小学到高中最完整的体系学程，
      让学校快速接轨全球教育最重要的STEAM课程，并且落实在校园中发展成功。
    </p>
  </div>
</section>
<section class="split-reverse">
  <div class="start fx-col align-end justify-center" style="padding: 30px">
    <div class="fx-col justify-center">
      <h3 style="font-weight: 500; max-width: 600px">
        国际认证！师培扎根！<ng-template *ngIf="!(isMobile$ | async)"
          >系统评鉴！</ng-template
        >
        <br />
        给学校素养课程坚实后盾！
      </h3>
      <p style="max-width: 600px">
        翻转机器人（FlipRobot）获得美国加州大学UC认证，是全球少数能正式发出美国中学成绩单的STEAM课程。我们与美国航天中心（NASA）青少年教育中心合作，打造火星探勘PBL课程。我们的教材、机器人、编程软件自研生产，获得全球专利及安规保障，这不仅呈现我们坚强的研发实力，也代表我们的课程真正可以做到培养学生创造力、批判性思考能力、问题解决等关键抽象能力。
      </p>
      <p style="max-width: 600px">
        我们正热诚的拜访优秀学校，认识并采用我们的STEAM教育方案，也招募教育经销商与我们合作，共同推动我们的课程让更多学生能培养STEAM、5C等被教育界认为21世纪最重要的关键竞争能力。
      </p>
    </div>
  </div>
  <div class="end">
    <div class="fx-row fx-wrap">
      <div
        style="max-width: 50%"
        *ngFor="let i of [1, 2, 3, 4]"
        (mouseenter)="onMouseEnter(i)"
        (mouseleave)="onMouseLeave()"
      >
        <img class="img-fluid" [src]="getImgSrc(i)" alt="" />
      </div>
    </div>
  </div>
</section>
<picture>
  <source
    media="(max-width: 600px)"
    srcset="assets/images/page/plan/school/3_mobile.jpg"
  />
  <img class="img-fluid" src="assets/images/page/plan/school/3.jpg" alt="" />
</picture>
<app-franchise-contact></app-franchise-contact>
<picture>
  <source
    media="(max-width: 600px)"
    srcset="assets/images/page/plan/school/4_mobile.jpg"
  />
  <img class="img-fluid" src="assets/images/page/plan/school/4.jpg" alt="" />
</picture>
<section>
  <div class="certificate">
    <div class="fx-col fx-center" style="padding: 0 30px">
      <h3 class="text-primary" style="font-weight: 500; margin: 30px 0">
        校本特色&nbsp;‧&nbsp;国际认证
      </h3>
      <p class="h5" style="max-width: 800px">
        「翻转机器人」FlipRobot教育提供国际学校、民办学校及公立学校完整的STEAM教育方案。
      </p>
    </div>
    <div class="split">
      <div class="start img-container">
        <picture>
          <source
            media="(max-width: 600px)"
            srcset="assets/images/page/plan/school/5_1_mobile.jpg"
          />
          <img
            class="img-fluid"
            src="assets/images/page/plan/school/5_1.jpg"
            alt=""
          />
        </picture>
        <div class="desc h6">我们的方案完整支持学校发展STEAM校本特色课程。</div>
      </div>
      <div class="end img-container">
        <picture>
          <source
            media="(max-width: 600px)"
            srcset="assets/images/page/plan/school/5_2_mobile.jpg"
          />
          <img
            class="img-fluid"
            src="assets/images/page/plan/school/5_2.jpg"
            alt=""
          />
        </picture>
        <div class="desc text-white h6">
          翻转机器人（FlipRobot）获得美国加州大学UC认证，是全球少数能正式发出美国中学成绩单的STEAM课程。
        </div>
      </div>
    </div>
    <div class="split">
      <div class="start img-container">
        <picture>
          <source
            media="(max-width: 600px)"
            srcset="assets/images/page/plan/school/5_3_mobile.jpg"
          />
          <img
            class="img-fluid"
            src="assets/images/page/plan/school/5_3.jpg"
            alt=""
          />
        </picture>
        <div class="desc text-white h6">
          完整的STEAM素质评析系统，诊断学生5C、STEAM跨领域能力。
        </div>
      </div>
      <div class="end img-container">
        <picture>
          <source
            media="(max-width: 600px)"
            srcset="assets/images/page/plan/school/5_4_mobile.jpg"
          />
          <img
            class="img-fluid"
            src="assets/images/page/plan/school/5_4.jpg"
            alt=""
          />
        </picture>
        <div class="desc h6">FlipRobot课程学术架构</div>
      </div>
    </div>
  </div>
</section>
<picture>
  <source
    media="(max-width: 600px)"
    srcset="assets/images/page/plan/school/6_mobile.jpg"
  />
  <img class="img-fluid" src="assets/images/page/plan/school/6.jpg" alt="" />
</picture>
<app-franchise-contact></app-franchise-contact>
