import { concatMap } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService, AuthService } from 'projects/tools/src/lib/services';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styles: [
    `
      .container {
        width: 360px;
        padding: 15px;
      }
      .logo {
        max-width: 180px;
      }
      .ant-form {
        width: 330px;
      }
      nz-input-group {
        min-height: 40px;
      }
      .anticon {
        line-height: 32px;
      }
      .agree-terms {
        align-self: start;
        label {
          margin-right: 10px;
        }
        a {
          margin-left: 4px;
        }
      }
      label {
        margin-right: 10px;
      }
    `,
  ],
})
export class SignupComponent implements OnInit {
  @Input() isModal: boolean;
  @Output() closeModel = new EventEmitter<any>();
  signupForm: FormGroup;
  isAgreeTerms = false;
  isLoading = false;
  accountType = 'phone';

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private http: HttpService,
    private message: NzMessageService,
  ) {}

  ngOnInit(): void {
    this.signupForm = this.fb.group({
      account: new FormControl(null, { updateOn: 'blur' }),
      verificationCode: [
        null,
        [Validators.required, Validators.pattern('[0-9]*')],
      ],
      password: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          Validators.pattern('[a-zA-Z0-9]*'),
        ],
        updateOn: 'blur',
      }),
      confirmPassword: new FormControl(null, {
        validators: [Validators.required, this.matchValidator],
        updateOn: 'blur',
      }),
      nickname: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
      name: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
      birthday: [null, Validators.required],
    });
  }
  matchValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    }
    return control.value !== this.form.password.value
      ? { match: true, error: true }
      : {};
  };
  get form() {
    return this.signupForm.controls;
  }
  onAccountTypeChange(accountType: string) {
    this.accountType = accountType;
    if (accountType === 'email') {
      this.form.account.setValidators([Validators.required, Validators.email]);
      this.form.account.updateValueAndValidity();
    } else {
      this.form.account.setValidators([
        Validators.required,
        Validators.pattern(
          /^((13[0-9])|(14[5,7,9])|(15([0-3]|[5-9]))|(16[5,6])|(17[0-8])|(18[0-9])|(19[1、5、8、9]))\d{8}$/,
        ),
      ]);
      this.form.account.updateValueAndValidity();
    }
  }
  checkFormValue(): void {
    for (const i of Object.keys(this.signupForm.controls)) {
      this.signupForm.controls[i].markAsDirty();
      this.signupForm.controls[i].updateValueAndValidity();
    }
  }
  submitSignupForm(): void {
    this.checkFormValue();
    if (!this.signupForm.invalid && this.isAgreeTerms) {
      const body = this.signupForm.getRawValue();
      delete body.confirmPassword;
      this.isLoading = true;
      this.signupForm.disable();
      this.http
        .post('/auth/signup', { ...body, format: this.accountType })
        .pipe(
          concatMap(() =>
            this.auth.login({
              account: this.form.account.value,
              password: this.form.password.value,
            }),
          ),
        )
        .subscribe(
          () => {
            this.message.success('注册成功');
            if (this.isModal) {
              this.signupForm.enable();
              this.signupForm.reset();
              this.closeModel.emit();
            } else {
              this.router.navigateByUrl('/');
            }
          },
          () => {
            this.signupForm.enable();
            this.isLoading = false;
          },
        );
    }
  }
}
