import { HttpService } from './../../../../../tools/src/lib/services/http.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService, RwdService } from 'projects/tools/src/lib/services';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-flip-tools',
  templateUrl: './flip-tools.component.html',
  styleUrls: ['./flip-tools.component.less'],
})
export class FlipToolsComponent implements OnInit, OnDestroy {
  isWeb$: Observable<boolean>;
  isLogin: boolean;
  isLogin$: Observable<boolean>;
  downloadAccess$: Observable<boolean>;
  destroy$ = new Subject();
  downloadUrl = 'software/flipaiot/FlipAIoT Setup 1.0.0.exe';
  downloads = [
    // FlipCode download or online
    [
      {
        name: 'Fliptool.btn.windows',
        href: 'https://fliprobot-assets.s3.cn-north-1.amazonaws.com.cn/software/flipcode/FlipCode+Setup+2.7.0.exe',
      },
      {
        name: 'Fliptool.btn.mac',
        href: 'https://fliprobot-assets.s3.cn-north-1.amazonaws.com.cn/software/flipcode/FlipCode-2.7.0.dmg',
      },
      {
        name: 'Fliptool.btn.ios',
        href: 'https://apps.apple.com/app/id1443474045',
      },
      {
        name: 'Fliptool.btn.online',
        href: 'https://tools.fliprobot.com',
      },
    ],
    // FlipRAS download or online
    [
      {
        name: 'Fliptool.btn.windows',
        href: 'https://fliprobot-assets.s3.cn-north-1.amazonaws.com.cn/software/flipras/FlipRAS+Setup+1.2.0.exe',
      },
      {
        name: 'Fliptool.btn.mac',
        href: 'https://fliprobot-assets.s3.cn-north-1.amazonaws.com.cn/software/flipras/FlipRAS-1.2.0.dmg',
      },
      {
        name: 'Fliptool.btn.ios',
        href: 'https://apps.apple.com/app/id1481079129',
      },
      {
        name: 'Fliptool.btn.online',
        href: 'https://ras.fliprobot.com/',
      },
    ],
    // FlipDuino download or online
    [
      {
        name: 'Fliptool.btn.windows',
        href: 'https://fliprobot-assets.s3.cn-north-1.amazonaws.com.cn/software/flipduino/FlipDuino+1.0.0.exe',
      },
      {
        name: 'Fliptool.btn.mac',
        href: 'https://fliprobot-assets.s3.cn-north-1.amazonaws.com.cn/software/flipduino/FlipDuino+1.0.0.dmg',
      },
    ],
    [
      {
        name: 'Fliptool.btn.windows',
        isDisabled: true,
        checkDownloadAccess: true,
      },
      {
        name: 'Fliptool.btn.online',
        href: 'https://aiot.fliprobot.com.cn',
        hasAccessToken: true,
      },
    ],
  ];
  open = (href: string) => window.open(href, '_blank');
  constructor(
    private rwd: RwdService,
    private auth: AuthService,
    private http: HttpService,
  ) {}

  ngOnInit(): void {
    this.isWeb$ = this.rwd.isWeb.pipe(shareReplay(1));
    this.auth
      .isLogin()
      .pipe(
        tap((isLogin) => (this.isLogin = isLogin)),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.downloadAccess$ = this.http
      .post('/software', {
        url: this.downloadUrl,
      })
      .pipe(
        map((data) => {
          if (data.signedUrl) {
            this.downloadUrl = data.signedUrl;
          }
          return !!data.signedUrl;
        }),
      );
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  get accessToken() {
    return `${localStorage.getItem('access_token')}`;
  }
}
