import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from 'projects/tools/src/lib/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: [
    `
      .container {
        max-width: 360px;
        padding: 15px;
      }
      .logo {
        max-width: 180px;
      }
      .ant-form {
        width: 330px;
      }
      .ant-btn {
        font-size: 16px;
        height: 40px;
        width: 100%;
      }
    `,
  ],
})
export class ResetPasswordComponent implements OnInit {
  @Input() isModal: boolean;
  @Output() resetPassword: EventEmitter<any> = new EventEmitter();
  resetPasswordForm: FormGroup;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private message: NzMessageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      account: new FormControl(null, {
        updateOn: 'blur',
      }),
      password: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
      verificationCode: [
        null,
        [Validators.required, Validators.pattern('[0-9]*')],
      ],
      confirmPassword: new FormControl(null, {
        validators: [Validators.required, this.matchValidator],
        updateOn: 'blur',
      }),
    });
  }
  get form() {
    return this.resetPasswordForm.controls;
  }
  onAccountTypeChange(type: string) {
    if (type === 'email') {
      this.form.account.setValidators([Validators.required, Validators.email]);
      this.form.account.updateValueAndValidity();
    } else {
      this.form.account.setValidators([
        Validators.required,
        Validators.pattern(
          /^((13[0-9])|(14[5,7,9])|(15([0-3]|[5-9]))|(16[5,6])|(17[0-8])|(18[0-9])|(19[1、5、8、9]))\d{8}$/,
        ),
      ]);
      this.form.account.updateValueAndValidity();
    }
  }
  checkFormValue(): void {
    for (const i of Object.keys(this.resetPasswordForm.controls)) {
      this.resetPasswordForm.controls[i].markAsDirty();
      this.resetPasswordForm.controls[i].updateValueAndValidity();
    }
  }
  submitRestPasswordForm(): void {
    this.checkFormValue();
    if (!this.resetPasswordForm.invalid) {
      const body = this.resetPasswordForm.getRawValue();
      delete body.confirmPassword;
      this.isLoading = true;
      this.resetPasswordForm.disable();
      this.http.post('/auth/reset-password', body).subscribe(
        () => {
          this.message.success('修改成功');
          if (this.isModal) {
            this.resetPasswordForm.reset();
            this.resetPassword.emit();
            this.resetPasswordForm.enable();
          } else {
            this.router.navigateByUrl('/auth/login');
          }
        },
        () => {
          this.resetPasswordForm.enable();
          this.isLoading = false;
        },
      );
    }
  }
  matchValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    }
    return control.value !== this.form.password.value
      ? { match: true, error: true }
      : {};
  };
}
