import { HttpService } from 'projects/tools/src/lib/services';
import { FormGroup } from '@angular/forms';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { fromEvent, interval, Observable } from 'rxjs';
import { finalize, take, tap, switchMap, filter } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styles: [
    `
      .ant-select {
        flex-basis: 35%;
        height: 40px;
      }
      .ant-input {
        min-height: 40px;
      }
      .send-code-btn {
        width: 40%;
        min-height: 40px;
        border-radius: 5px;
        float: right;
        border: solid 1px #c6c8cb;
        background-color: #ffffff;
      }
      .send-code-btn[disabled] {
        color: rgba(16, 16, 16, 0.3);
        background-color: rgba(239, 239, 239, 0.3);
        border-color: rgba(195, 195, 195, 0.3);
        cursor: not-allowed;
      }
    `,
  ],
})
export class AccountComponent implements OnInit, AfterViewInit {
  @Input() inputForm: FormGroup;
  @Input() isLogin: boolean;
  @Output() accountTypeChange: EventEmitter<string> = new EventEmitter();
  @ViewChild('sendCodeBtn', { static: false }) sendCodeBtn: ElementRef;
  btnClick$: Observable<any>;
  isBtnActive = true;
  time = 60;
  isPhoneType: boolean;
  accountType: string;
  constructor(private http: HttpService, private message: NzMessageService) {}
  ngAfterViewInit(): void {
    if (!this.isLogin) {
      // errors when @ViewChild in *ngIf
      this.btnClick$ = fromEvent(this.sendCodeBtn.nativeElement, 'click').pipe(
        filter(() => !this.form.account.invalid),
        tap(() => (this.isBtnActive = false)),
        tap(() => this.form.account.disable()),
        switchMap(() => interval(1000)),
        take(59),
        tap(() => (this.time = this.time - 1)),
        finalize(() => {
          this.resetBtnState();
          this.btnClick$.subscribe();
        }),
      );
      this.btnClick$.subscribe();
    }
  }
  ngOnInit(): void {
    this.accountType = localStorage.getItem('account_type') ?? 'phone';
    this.isPhoneType = this.accountType === 'phone';
    this.accountTypeChange.emit(this.accountType);
  }
  get form() {
    return this.inputForm.controls;
  }

  onAccountTypeChange($event: string) {
    this.isPhoneType = $event === 'phone';
    this.accountTypeChange.emit($event);
    localStorage.setItem('account_type', $event);
  }
  onCodeBtnClick(): void {
    this.form.account.markAsDirty();
    this.form.account.updateValueAndValidity();
    if (!this.form.account.invalid) {
      this.http
        .post('/auth/verification', {
          account: this.form.account.value,
          format: this.isPhoneType ? 'phone' : 'email',
        })
        .subscribe(
          () =>
            this.message.success('验证码寄送成功！请于15分钟内输入验证码。', {
              nzDuration: 10000,
            }),
          (err) => {
            console.log(err);
          },
        );
    }
  }
  resetBtnState(): void {
    this.isBtnActive = true;
    this.time = 60;
    this.form.account.enable();
  }
}
