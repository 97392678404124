import { NzMessageService } from 'ng-zorro-antd/message';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[toolsWebShare]',
})
export class WebShareDirective {
  @Input() webShareText: string;
  @Input() webShareTitle: string;
  constructor(private el: ElementRef, private message: NzMessageService) {}

  @HostListener('click') onMouseEnter() {
    // only supported in HTTPS
    if (navigator['share']) {
      navigator['share']({
        title: this.webShareTitle,
        text: this.webShareText,
        url: location.href,
      });
    }
    this.copy();
  }
  private copy() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = location.href;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.message.info('連結已複製');
  }
}
