<main class="fx-col fx-center" [ngClass]="isModal ? 'bg-white' : 'bg-light'">
  <div
    class="container fx-col fx-center"
    [style.min-height]="isModal ? '' : 'calc(100vh - 64px)'"
    style="gap: 15px"
  >
    <picture class="logo" *ngIf="!isModal">
      <img class="img-fluid" src="assets/images/logo.png" alt="" />
    </picture>
    <form nz-form [formGroup]="signupForm">
      <app-account
        [inputForm]="signupForm"
        (accountTypeChange)="onAccountTypeChange($event)"
      ></app-account>
      <app-new-password [inputForm]="signupForm"></app-new-password>
      <ng-template #userIcon> <i nz-icon nzType="user"></i></ng-template>
      <nz-form-item>
        <nz-form-control
          [nzErrorTip]="
            'error.required'
              | translate: { value: ('auth.nickname' | translate) }
          "
          [nzExtra]="'auth.nicknameTip' | translate"
        >
          <nz-input-group [nzPrefix]="userIcon">
            <input
              formControlName="nickname"
              nz-input
              type="text"
              nzSize="large"
              [placeholder]="'auth.nickname' | translate"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control
          [nzErrorTip]="
            'error.required'
              | translate: { value: ('auth.username' | translate) }
          "
          [nzExtra]="'auth.usernameTip' | translate"
        >
          <nz-input-group [nzPrefix]="userIcon">
            <input
              nz-input
              type="text"
              formControlName="name"
              nzSize="large"
              [placeholder]="'auth.username' | translate"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control
          [nzErrorTip]="
            'error.required'
              | translate: { value: ('auth.birthday' | translate) }
          "
        >
          <nz-date-picker
            formControlName="birthday"
            [nzPlaceHolder]="'auth.birthday' | translate"
            nzSize="large"
            nzAllowClear
            style="width: 330px"
            [nzInputReadOnly]="true"
          >
          </nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </form>
    <div class="agree-terms">
      <label nz-checkbox [(ngModel)]="isAgreeTerms"> </label>
      <span>{{ 'auth.agreeTerms' | translate }}</span>
      <a class="text-primary" href="/assets/static/site.html" target="_blank"
        >{{ 'auth.useTerms' | translate | titlecase }} </a
      >&
      <a
        class="text-primary"
        href="/assets/static/privacy.html"
        target="_blank"
        >{{ 'auth.privacyPolicy' | translate | titlecase }}</a
      >。
    </div>
    <button
      nz-button
      nzType="primary"
      nzBlock
      nzSize="large"
      [nzLoading]="isLoading"
      [disabled]="!isAgreeTerms"
      (click)="submitSignupForm(); $event.preventDefault()"
    >
      {{ 'auth.signup' | translate | titlecase }}
    </button>
  </div>
</main>
