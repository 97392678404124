<div [formGroup]="inputForm">
  <nz-form-item>
    <ng-template #lockIcon>
      <i nz-icon nzType="lock"></i>
    </ng-template>
    <ng-template #eyeIcon>
      <i
        nz-icon
        [nzType]="isPasswordVisible ? 'eye' : 'eye-invisible'"
        (click)="isPasswordVisible = !isPasswordVisible"
      ></i>
    </ng-template>
    <nz-form-control
      [nzErrorTip]="
        form.password.hasError('required')
          ? ('error.required'
            | translate: { value: ('auth.password' | translate) })
          : form.password.hasError('pattern')
          ? ('error.passwordFormat' | translate)
          : ('error.passwordLength' | translate)
      "
    >
      <nz-input-group [nzSuffix]="eyeIcon" [nzPrefix]="lockIcon">
        <input
          [placeholder]="'auth.password' | translate"
          [type]="isPasswordVisible ? 'text' : 'password'"
          formControlName="password"
          nz-input
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control
      [nzErrorTip]="
        form.confirmPassword.hasError('match')
          ? ('error.passwordMatch' | translate)
          : ('error.required'
            | translate: { value: ('auth.confirmPassword' | translate) })
      "
    >
      <nz-input-group [nzPrefix]="lockIcon">
        <input
          nz-input
          [placeholder]="'auth.confirmPassword' | translate"
          type="password"
          formControlName="confirmPassword"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
</div>
