import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'projects/tools/src/lib/services';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styles: [
    `
      .logo {
        max-width: 180px;
      }
      .tab-btn {
        font-size: 18px;
      }
    `,
  ],
})
export class LoginModalComponent implements OnInit, OnChanges {
  @Input() isVisible: boolean;
  @Output() modalCancel: EventEmitter<any> = new EventEmitter();
  tabIndex = 0;
  isResetPassword = false;
  constructor(private auth: AuthService, private router: Router) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (location.pathname.includes('auth')) {
      this.isVisible = false;
    }
  }

  ngOnInit(): void {}
  onModalCancel() {
    this.modalCancel.emit();
    this.isVisible = false;
  }
}
