import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-classroom-plan',
  templateUrl: './classroom-plan.component.html',
  styleUrls: ['./classroom-plan.component.less'],
})
export class ClassroomPlanComponent implements OnInit {
  slogans: string[] = [
    '国际品牌<br>竞争优势',
    '完整教培<br>轻松加盟',
    '系统课程<br>形象导入',
    'AI编程<br>专利机器人',
  ];
  constructor() {}

  ngOnInit() {}
}
