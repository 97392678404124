import {
  AuthService,
  RwdService,
  LangService,
} from 'projects/tools/src/lib/services';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { CountryMap } from 'projects/tools/src/lib/pipes/country.pipe';
import { Observable, of } from 'rxjs';
import { shareReplay, map, concatMap, filter } from 'rxjs/operators';
import { UserDataSource, IUser } from 'projects/tools/src/lib/data/user.data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less'],
})
export class NavbarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() screenTop: boolean;
  avatar$: Observable<string>;
  country: { icon: string; label: string; lang: string; value: string };
  countryOptions: any;
  isLogin$: Observable<boolean>;
  isAuthPath: boolean;
  isManager$: Observable<boolean>;
  isMenuVisible = false;
  isModalVisible = false;
  isWeb$: Observable<boolean>;
  navbarBgColor: string;
  navbarBrandImg: string;
  navbarFontColor: string;
  reload = false;
  mobileDropdownStyle = {
    'background-color': '#fff',
    'box-shadow': '2px 2px 6px 0 rgba(3, 27, 78, 0.1)',
    width: '100vw',
  };
  navItems = {
    home: {
      label: 'home',
      routerLink: '/',
      icon: 'home',
    },
    project: {
      label: 'player.project',
      routerLink: '/player/projects',
      icon: 'rocket',
    },
    tool: {
      label: 'tools',
      routerLink: '/tools',
      icon: 'tool',
    },
    user: [
      {
        label: 'user.info',
        link: '/user',
        icon: 'user',
      },
      {
        label: 'user.setting',
        link: '/user/setting',
        icon: 'setting',
      },
      {
        label: 'user.card',
        link: '/user/cards',
        icon: 'credit-card',
      },
      {
        label: 'user.project',
        link: '/user/projects',
        icon: 'rocket',
      },
    ],
    product: [
      {
        label: 'product.introduction',
        link: '/courses',
        query: { tab: 0 },
        icon: 'read',
      },
      // {
      //   label: 'product.classroom',
      //   link: '/plan/classroom',
      //   icon: 'read',
      // },
      {
        label: 'product.school',
        link: '/plan/school',
        icon: 'read',
      },
    ],
    certificate: [
      {
        label: 'certificate.teacher',
        link: '/certificate/teacher',
        icon: 'read',
      },
      {
        label: 'certificate.student',
        link: '/certificate/student',
        icon: 'read',
      },
    ],
  };
  constructor(
    private auth: AuthService,
    private dataSource: UserDataSource,
    private rwd: RwdService,
    private langService: LangService,
    private router: Router,
  ) {}

  get pathname(): string {
    return location.pathname;
  }
  ngOnInit(): void {
    this.avatar$ = this.dataSource.connect().pipe(
      concatMap((data) =>
        data === null ? this.dataSource.loadUser() : of(data),
      ),
      map((user: IUser) => user.avatar),
      shareReplay(1),
    );
    this.country = CountryMap[localStorage.getItem('country')];
    this.countryOptions = CountryMap;
    this.isLogin$ = this.auth.isLogin().pipe(shareReplay(1));
    this.isAuthPath = location.pathname === '/auth';
    this.isManager$ = this.dataSource.connect().pipe(
      filter((data) => data !== null),
      map((user: IUser) => user.isManager),
      shareReplay(1),
    );
    this.isWeb$ = this.rwd.isWeb.pipe(shareReplay(1));
    this.screenTop = location.pathname.substring(3) === '/';
  }
  ngAfterViewInit(): void {
    document.getElementsByClassName('banner')[0]?.scrollIntoView();
  }
  ngOnChanges(): void {
    this.onActivate();
  }
  setCountry(option: { key: string; value: any }) {
    localStorage.setItem('country', option.key.toUpperCase());
    localStorage.setItem('lang', option.value.lang);
    location.reload();
  }
  onActivate(componentRef?: any) {
    this.isAuthPath = location.pathname === '/auth';
    this.navbarBgColor = this.screenTop ? 'rgba(0, 0, 0, 0.5)' : '#fff';
    this.navbarBrandImg = `assets/images/${
      this.screenTop ? 'logo_white.png' : 'logo.png'
    }`;
    this.navbarFontColor = this.screenTop ? '#fff' : '#000';
    if (this.auth.loggedIn && location.pathname.includes('auth')) {
      this.router.navigateByUrl('/');
    }
    if (componentRef && componentRef.showLoginModal) {
      componentRef.showLoginModal.subscribe(
        (isModalVisible: boolean) => (this.isModalVisible = isModalVisible),
      );
    }
    if (componentRef && componentRef.reload) {
      componentRef.reload.subscribe(
        (reload: boolean) => (this.reload = reload),
      );
    }
    this.reload = false;
    document.getElementsByClassName('banner')[0]?.scrollIntoView();
  }
  logout(): void {
    this.auth.logout();
    if (this.reload) {
      location.reload();
    }
  }
  currentLanguage() {
    return this.langService.translate.currentLang;
  }
  useLanguage(lang: string) {
    this.langService.setLang(lang);
  }
  onMenuItemClick() {
    this.isMenuVisible = !this.isMenuVisible;
  }
}
