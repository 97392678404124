<section class="banner bg fx-center fx-col">
  <h1 class="no-margin font-bold text-white">教师评鉴</h1>
  <p class="h5 no-margin font-bold text-white">
    拓展教师新知能，素养教育为首选。<br />
    欢迎加入FlipRobot官方核准教师！
  </p>
</section>
<section id="case" class="split bg">
  <div class="start fx-col align-end justify-center">
    <div class="fx-col justify-center" style="padding: 0 30px">
      <h3 class="label-title">FlipRobot 教师评鉴</h3>
      <p style="max-width: 600px">
        FlipRobot的STEAM素质教育师资评鉴是由我们与美国CASE教育基金会合作发展的评鉴制度，教师必须先透过培训，精熟我们的教师手册与教材及学具。接下来透过测验、试教过程，合格者取得我们的评鉴资格。合格师资将获得证书，并成为FlipRobot全球STEAM师资的一员。
      </p>
    </div>
  </div>
  <div class="end fx-col justify-center" style="padding: 0 30px">
    <div class="fx-row align-center">
      <img src="assets/images/page/certificate/teacher/case.png" alt="" />
      <img src="assets/images/page/certificate/teacher/actura.png" alt="" />
    </div>
  </div>
</section>
<section id="why-teacher-training">
  <div class="container">
    <div class="fx-row fx-center justify-between reason-wrapper">
      <div
        *ngFor="let reason of 'Training.why.reason' | translate; let i = index"
        class="fx-col justify-end reason"
        (mouseenter)="onMouseEnter(i)"
        (mouseleave)="onMouseLeave()"
      >
        <picture>
          <img class="img-fluid" [src]="getImgSrc(i)" class="hover" />
        </picture>
        <div class="fx-col fx-center text-start">
          <h4>{{ reason.heading }}</h4>
          <p class="h6 text-center no-margin">{{ reason.paragraph }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="fx-row" id="advantage-section">
  <picture>
    <source
      media="(max-width: 600px)"
      srcset="assets/images/page/certificate/teacher/3_mobile.jpg"
    />
    <img src="assets/images/page/certificate/teacher/3.jpg" alt="" />
  </picture>
  <div class="end">
    <picture>
      <source
        media="(max-width: 600px)"
        srcset="assets/images/page/certificate/teacher/4_mobile.jpg"
      />
      <img
        class="bg-img"
        src="assets/images/page/certificate/teacher/4.jpg"
        alt=""
      />
    </picture>
    <div class="fx-col" style="padding: 20px">
      <h4 class="label-title" style="margin-bottom: 30px">
        取得FlipRobot 教师评鉴优势
      </h4>
      <div class="fx-row fx-wrap">
        <div
          class="h5 advantage text-primary font-bold"
          *ngFor="let advantage of advantages"
          [innerHTML]="advantage"
        ></div>
      </div>
    </div>
  </div>
</section>
<section id="assessment-section">
  <div class="container">
    <h4 class="label-title" style="margin-bottom: 30px">教师评鉴考核项目</h4>
    <p class="h6">FlipRobot的STEAM素质教育师资评鉴必须考核以下五个部分</p>
    <div class="fx-row fx-wrap justify-between">
      <div
        class="assessment"
        *ngFor="let assessment of assessments; let i = index"
      >
        <picture>
          <source
            media="(max-width: 600px)"
            [srcset]="
              'assets/images/page/certificate/teacher/6_' +
              (i + 1) +
              '_mobile.png'
            "
          />
          <img
            [src]="
              'assets/images/page/certificate/teacher/6_' + (i + 1) + '.png'
            "
            alt=""
          />
        </picture>
        <h5 class="text-primary font-bold">{{ assessment.title }}</h5>
        <p>{{ assessment.desc }}</p>
      </div>
    </div>
    <picture>
      <source
        media="(max-width: 600px)"
        srcset="assets/images/page/certificate/teacher/6_7_mobile.png"
      />
      <img
        class="img-fluid"
        src="assets/images/page/certificate/teacher/6_7.png"
        alt=""
      />
    </picture>
  </div>
</section>
<section id="certificate-level">
  <h4 class="text-primary text-center">评鉴分级</h4>
  <div class="fx-col up">
    <div class="img-bg">
      <div class="container fx-row">
        <div class="img-wrapper">
          <img
            class="img-fluid"
            src="assets/images/page/certificate/teacher/9_1.png"
            alt=""
          />
        </div>
        <div class="img-wrapper">
          <img
            class="img-fluid"
            src="assets/images/page/certificate/teacher/9_2.png"
            alt=""
          />
        </div>
        <div class="img-wrapper">
          <img
            class="img-fluid"
            src="assets/images/page/certificate/teacher/9_3.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <div class="down">
    <div class="container fx-col">
      <div class="level-wrapper fx-row justify-center">
        <div class="img-wrapper">
          <img src="assets/images/page/certificate/teacher/9_4.png" alt="" />
        </div>
        <div class="fx-col desc-wrapper">
          <h5 class="font-bold" style="color: #5a5657">Topic主题讲师</h5>
          <p>
            •评测合格将获得指定【Topic主题讲师】的官方核准讲师​。<br />
            •可开始以官方核准讲师身份对外进行授课。<br />
            •教师的姓名可以公开于FlipRobot网站上，并获得教育机构授课邀约。
          </p>
        </div>
      </div>
      <div class="level-wrapper fx-row justify-center">
        <div class="img-wrapper">
          <img src="assets/images/page/certificate/teacher/9_5.png" alt="" />
        </div>
        <div class="fx-col desc-wrapper">
          <h5 class="font-bold" style="color: #d5427f">Program学级高级讲师</h5>
          <p>
            •必须取得至少3主题及格评鉴，方具备考核​资格。<br />
            •评测合格将获得指定【Program学级高级讲师】的官方核准讲师​。<br />
            •可开始以学级高级讲师身分对外进行师培课程。<br />
            •教师的姓名可以公开于FlipRobot网站上，并获得教育机构授课邀约。
          </p>
        </div>
      </div>
      <div class="level-wrapper fx-row justify-center">
        <div class="img-wrapper">
          <img src="assets/images/page/certificate/teacher/9_6.png" alt="" />
        </div>
        <div class="fx-col desc-wrapper">
          <h5 class="font-bold" style="color: #23b6bc">
            LEVEL学程级专家级讲师
          </h5>
          <p>
            •必须取得至少3学级及格评鉴，方具备考证资格。<br />
            •评测合格将获得指定【Level专家级讲师】的官方核准讲师​。<br />
            •可开始以专家级讲师身分对外进行师培课程。<br />
            •教师的姓名可以公开于FlipRobot网站上，并获得教育机构授课邀约。<br />
            •能以资深专业讲师身分在网页分享机器人课程，并提供考核方式。
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="contact" class="bg fx-col fx-center">
  <div class="container">
    <div class="fx-col justify-center">
      <h3
        class="text-white"
        [ngClass]="(isMobile$ | async) ? 'text-center' : ''"
      >
        合作方案 ✕ 成为STEAM教育革命者
      </h3>
      <p class="h5 text-white">
        我们提供完整的机器人STEAM教材、课程、师培与教具产品方案给
        学校、经销商、教育合作、STEAM教育革命者们
      </p>
      <div
        class="wechat-group"
        [ngClass]="(isMobile$ | async) ? 'fx-col align-center' : 'fx-row'"
      >
        <img src="assets/images/page/certificate/teacher/wechat_1.png" alt="" />
        <img src="assets/images/page/certificate/teacher/wechat_2.png" alt="" />
      </div>
    </div>
  </div>
</section>
