import { AntdModule } from 'projects/tools/src/lib/antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AccountComponent } from './form/account/account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewPasswordComponent } from './form/new-password/new-password.component';
import { TranslateModule } from '@ngx-translate/core';

import { LoginModalComponent } from './login-modal/login-modal.component';
import { ToolsModule } from 'projects/tools/src/lib/tools.module';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    AccountComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    LoginModalComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AntdModule,
    TranslateModule,
    ToolsModule,
  ],
  exports: [
    LoginComponent,
    SignupComponent,
    ResetPasswordComponent,
    LoginModalComponent,
    NewPasswordComponent,
  ],
})
export class AuthModule {}
