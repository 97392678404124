<main class="fx-col fx-center" [ngClass]="isModal ? 'bg-white' : 'bg-light'">
  <div
    class="container fx-col fx-center"
    [style.min-height]="isModal ? '' : 'calc(100vh - 64px)'"
    style="gap: 15px"
  >
    <picture class="logo" *ngIf="!isModal">
      <img class="img-fluid" src="assets/images/logo.png" alt="" />
    </picture>
    <form nz-form [formGroup]="resetPasswordForm">
      <app-account
        [inputForm]="resetPasswordForm"
        (accountTypeChange)="onAccountTypeChange($event)"
      ></app-account>
      <app-new-password [inputForm]="resetPasswordForm"></app-new-password>
    </form>
    <button
      nz-button
      nzType="primary"
      (click)="submitRestPasswordForm()"
      [nzLoading]="isLoading"
    >
      {{ 'auth.resetPassword' | translate | titlecase }}
    </button>
  </div>
</main>
