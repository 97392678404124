<app-navbar [(screenTop)]="screenTop"></app-navbar>
<main style="position: relative">
  <nz-carousel nzEffect="fade" nzAutoPlay [nzAutoPlaySpeed]="60000">
    <section
      [style.background-image]="carouselBgImg(i) | safe: 'style'"
      *ngFor="let carousel of 'Home.carousel' | translate; let i = index"
      nz-carousel-content
    >
      <div class="container no-padding">
        <div class="banner fx-col justify-center">
          <h1 [innerHTML]="carousel.heading" class="font-bold text-white"></h1>
          <p class="h5 text-white">{{ carousel.paragraph }}</p>
          <a class="btn btn-outline-primary no-border" routerLink="/about-us">
            {{ 'Shared.btn.learn_more' | translate }}
          </a>
          <div class="qr-code-container" *ngIf="isWeb$ | async">
            <picture>
              <img
                class="qr-code"
                src="/assets/images/page/home/actura-qrcode.jpg"
                alt=""
              />
            </picture>
            <div class="qr-code-description">
              <img class="wechat-icon" src="/assets/icon/wechat.png" alt="" />
              <span>璞学至慧教育Wechat公众号</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </nz-carousel>
  <section id="core-value">
    <div class="container">
      <h2 class="text-center">{{ 'Home.core.heading' | translate }}</h2>
      <div class="fx-row fx-center justify-between value-wrapper">
        <div
          *ngFor="let value of 'Home.core.value' | translate; let i = index"
          class="fx-col justify-end value"
        >
          <picture>
            <img class="img-fluid" [src]="valueImg(i, 'default')" />
            <img class="img-fluid" [src]="valueImg(i, 'hover')" class="hover" />
          </picture>
          <div class="fx-col fx-center text-center">
            <h4 [innerHTML]="value.heading"></h4>
            <p [innerHTML]="value.paragraph" class="h6 no-margin"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="fliprobot-service">
    <ng-container *ngFor="let img of 'Home.service' | translate; let i = index">
      <picture>
        <img
          [ngClass]="selected === i ? 'selected' : ''"
          [src]="serviceBgImg(i)"
          class="img-fluid"
        />
      </picture>
    </ng-container>
    <section class="select">
      <div
        *ngFor="let service of 'Home.service' | translate; let i = index"
        (click)="selected = i"
        (mouseenter)="isHover[i] = true"
        (mouseleave)="isHover[i] = false"
        [style.background-color]="
          selected === i
            ? 'rgba(0, 0, 0, 0.4)'
            : isHover[i]
            ? 'rgba(0, 0, 0, 0.2)'
            : 'transparent'
        "
        class="option"
      >
        <h4 class="text-white">{{ service.heading }}</h4>
        <p *ngIf="selected == i" class="h6 text-white">
          {{ service.paragraph }}
        </p>
      </div>
    </section>
  </section>
  <section class="bg" id="steam-to-world">
    <div class="container fx-col fx-center">
      <h2 class="text-white text-center">
        {{ 'Home.world.heading' | translate }}
      </h2>
      <p class="h6 text-white">
        {{ 'Home.world.paragraph' | translate }}
      </p>
    </div>
  </section>
</main>
<app-footer></app-footer>
