import { Pipe, PipeTransform } from '@angular/core';

export const subjectMap = {
  course: '備課系統',
  url: '資源下載',
  software: '軟體',
};

@Pipe({ name: 'subject' })
export class SubjectPipe implements PipeTransform {
  transform(
    subject: string,
    condition: {
      code?: string;
      lang?: string;
      product?: string;
      type?: string;
    } = {},
  ): string {
    return (
      subjectMap[subject] +
      (Object.keys(condition).length === 0
        ? ''
        : subject === 'software'
        ? ` (${condition.type})`
        : ` (${condition.code} / ${condition.product} / ${condition.lang})`)
    );
  }
}
