import { Observable } from 'rxjs';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { RwdService } from 'projects/tools/src/lib/services';

@Component({
  selector: 'app-school-plan',
  templateUrl: './school-plan.component.html',
  styleUrls: ['./school-plan.component.less'],
})
export class SchoolPlanComponent implements OnInit {
  isMobile$: Observable<boolean>;
  hoverIndex = -1;
  constructor(private rwd: RwdService) {}

  ngOnInit() {
    this.isMobile$ = this.rwd.isMobile.pipe(shareReplay(1));
  }
  onMouseEnter(index: number) {
    this.hoverIndex = index;
  }
  onMouseLeave() {
    this.hoverIndex = -1;
  }
  getImgSrc(index: number) {
    return `assets/images/page/plan/school/7_${index}${
      this.hoverIndex === index ? '_hover' : ''
    }.png`;
  }
}
