<section class="banner bg fx-center fx-col">
  <h1 class="no-margin font-bold text-white">学习履历</h1>
  <p class="h5 no-margin font-bold text-white">
    启发孩子学STEAM，5C素质再提升！<br />
    充实你的学习履历！
  </p>
</section>
<section id="certificate-section" class="split bg">
  <div class="start fx-col align-end justify-center">
    <div class="fx-col justify-center" style="padding: 0 30px">
      <h3 class="label-title">学习履历来自完整素质评鉴</h3>
      <p style="max-width: 550px">
        FlipRobot打造完整的学程体系，提供学生可以有系统的进行STEAM学习。我们的每一套课程、每一个学习主题包含丰富的学习内容，并且具备严谨的教育学术评鉴方式，可以评析学生的学习成果，并提供能力强弱点分析，有助于检视孩子关键能力的学习成长变化。
      </p>
    </div>
  </div>
  <div class="end fx-col justify-center" style="padding: 0 30px">
    <div class="fx-row align-center">
      <img src="assets/images/page/certificate/teacher/case.png" alt="" />
      <img src="assets/images/page/certificate/teacher/actura.png" alt="" />
    </div>
  </div>
</section>
<section id="slogan-section">
  <div class="container">
    <div class="slogan-wrapper fx-center fx-col">
      <h3 style="padding: 0 30px">一站式STEAM素质教培加盟方案！</h3>
      <div
        class="slogan-container align-center fx-row justify-between"
        style="width: 100%"
      >
        <div class="slogan fx-row fx-center" *ngFor="let slogan of slogans">
          <div class="h3" [innerHtml]="slogan"></div>
        </div>
      </div>
    </div>
    <div class="split-reverse">
      <div class="start">
        <picture>
          <source
            media="(max-width: 600px)"
            srcset="assets/images/page/certificate/student/3_mobile.jpg"
          />
          <img
            class="img-fluid"
            src="assets/images/page/certificate/student/3.jpg"
            alt=""
          />
        </picture>
      </div>
      <div class="end" style="padding: 30px">
        <h3 class="font-bold">
          素质评鉴与学习履历<br />
          从现在开始！
        </h3>
        <p style="max-width: 600px">
          我们的能力评鉴系统深具教育学术基础，是针对一般应试教育难以评鉴的抽象能力；如合作、沟通、批判性思考等「21世纪关键竞争力」及STEAM跨学科能力。透过学习单、成果互评检验及教师观察，最终透过大数据分析，生成具鉴别力的学生能力分析单。随着学生参与的课堂愈多，累积的能力数据将可以更清晰的提供学生未来发展方向的建议。
        </p>
        <p style="max-width: 600px">
          我们的学生评鉴是以成绩证书​​方式呈现，除了书面的学习证书，并包括线上的成绩查询与分析。
        </p>
      </div>
    </div>
  </div>
</section>
<section id="feature-section">
  <div class="container">
    <h4 class="text-primary text-center font-bold">FlipRobot 学习评鉴特点</h4>
    <div class="fx-col justify-center">
      <div class="feature" *ngFor="let feature of features; let i = index">
        <picture>
          <source
            media="(max-width: 600px)"
            [srcset]="
              'assets/images/page/certificate/student/5_' +
              (i + 1) +
              '_mobile.png'
            "
          />
          <img
            [src]="
              'assets/images/page/certificate/student/5_' + (i + 1) + '.png'
            "
            alt=""
          />
        </picture>
        <h5 class="text-primary font-bold">{{ feature.title }}</h5>
        <p>{{ feature.desc }}</p>
      </div>
    </div>
  </div>
</section>
<section id="diagnosis-section">
  <div class="container fx-col fx-center">
    <h4 class="text-primary text-center font-bold">素质能力诊断图</h4>
    <p class="h6">
      完整的STEAM素质评析系统，诊断学生5C、STEAM跨领域能力。
      实线为全球大数据生成的能力标准，色块为学生的得分成绩数据。
    </p>
    <picture>
      <source
        media="(max-width: 600px)"
        srcset="assets/images/page/certificate/student/6_mobile.png"
      />
      <img
        class="img-fluid"
        src="assets/images/page/certificate/student/6.png"
        alt=""
      />
    </picture>
  </div>
</section>
<section class="split">
  <div class="start img-container">
    <picture>
      <source
        media="(max-width: 600px)"
        srcset="assets/images/page/certificate/student/7_1_mobile.jpg"
      />
      <img
        class="img-fluid"
        src="assets/images/page/certificate/student/7_1.jpg"
        alt=""
      />
    </picture>
    <div class="desc h6">以机器人、编程为载体，学习STEAM跨学科能力。</div>
  </div>
  <div class="end img-container">
    <picture>
      <source
        media="(max-width: 600px)"
        srcset="assets/images/page/certificate/student/7_2_mobile.jpg"
      />
      <img
        class="img-fluid"
        src="assets/images/page/certificate/student/7_2.jpg"
        alt=""
      />
    </picture>
    <div class="desc text-white h6">
      完整的课程体系，有系统的培养孩子的关键竞争素质力。
    </div>
  </div>
</section>
<section id="contact-section" class="fx-col fx-center" style="padding: 30px">
  <a routerLink="/courses" [queryParams]="{ tab: 1 }" class="text-primary"
    >认识我们的课程体系</a
  >
  <p style="max-width: 600px">
    如果您对于我们的学生评鉴方案有近一步的问题或想法，欢迎联系我们。
  </p>
  <div class="wechat-group fx-row fx-center">
    <img
      class="img-fluid"
      src="assets/images/page/certificate/teacher/wechat_1.png"
      alt=""
    />
    <img
      class="img-fluid"
      src="assets/images/page/certificate/teacher/wechat_2.png"
      alt=""
    />
  </div>
</section>
