export const MetaData = [
  {
    meta: {
      description:
        'FlipRobot翻转机器人由课程设计出发，发展出完整的STEAM素养学习架构，清楚的让学生透过AI与机器人专题，学习21世纪关键能力。研发团队由经验丰富的教师组成，发展包括机器人教具、STEAM素养教材、师培课程…等，完整的教育解决方案。',
    },
    path: '/',
    title: '翻转机器人',
  },
  {
    meta: {
      description:
        '2014年Actura璞学智能在澳洲雪梨成立，以翻转教育为教育理念，提供领先全球的校内及校外STEAM解决方案。在校内，FlipRobot翻转机器人提供了一个最佳AI机器人STEAM教育解决方案。',
    },
    path: '/about-us',
    title: '关于我们',
  },
  {
    meta: {
      description:
        '欢迎联系FlipRobot翻转机器人，如果您有任何指教或合作提案，欢迎利用在线表单与我们联系，我们将于工作日尽快回复您的询问。',
    },
    path: '/contact-us',
    title: '联系我们',
  },
  {
    meta: {
      description:
        '教学的强力推手，发展完整的云端学习系统，提供教师丰富的跨领域课程主题教材、教学资源下载及教学知识库。让STEAM机器人素养教学变得更容易。',
    },
    path: '/courses',
    title: '翻转课程',
  },
  {
    path: '/auth/login',
    title: '登录',
  },
  {
    path: '/auth/signup',
    title: '注册',
  },
  {
    path: '/player/projects',
    title: '小专题',
  },
  {
    path: '/auth/reset-password',
    title: '重设密码',
  },
  {
    meta: {
      description:
        '针对各学龄开发不同阶段的运算思维工具，例如:FlipAiot、FlipCode、FlipRAS、FlipDuino，FlipRobot相信唯有从小扎根、循序渐进，AI教育才能渗入每个细胞思维。',
    },
    path: '/tools',
    title: '翻转工具',
  },
  {
    meta: {
      description:
        '迈向素养教室的前哨站，一体式STEAM素养机器人师培课程，提供完整学期的授课投影片、教师手册、机器人，让不同领域的老师也能轻松上手跨领域素养课程。',
    },
    path: '/teacher/training',
    title: '师培中心',
  },
  {
    path: '/unlock-card',
    title: '解锁课程',
  },
  {
    path: '/user',
    title: '个人信息',
  },
  {
    path: '/user/setting',
    title: '帐号设置',
  },
  {
    path: '/user/cards',
    title: '序号管理',
  },
  {
    path: '/user/projects',
    title: '专题管理',
  },
  {
    path: '/404',
    title: '404',
  },
];
