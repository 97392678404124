import { IconDefinition } from '@ant-design/icons-angular';
import {
  FileTextOutline,
  MenuOutline,
  PlusOutline,
  LoginOutline,
  LogoutOutline,
  EyeInvisibleOutline,
  EyeOutline,
  MailOutline,
  LockOutline,
  UserOutline,
  CreditCardOutline,
  GoogleOutline,
  SettingOutline,
  NotificationOutline,
  FileImageOutline,
  DeleteOutline,
  CloseOutline,
  SearchOutline,
  DownloadOutline,
  HeartOutline,
  BookOutline,
  ShareAltOutline,
  RocketOutline,
  MoreOutline,
  ArrowRightOutline,
  LinkOutline,
  RotateRightOutline,
  ExpandOutline,
  CompressOutline,
  TeamOutline,
  SaveOutline,
  MinusOutline,
  QuestionOutline,
  ArrowLeftOutline,
} from '@ant-design/icons-angular/icons';
import {
  EditFill,
  DeleteFill,
  LockFill,
  BulbFill,
  HomeFill,
  PlaySquareFill,
  ReadFill,
  ToolFill,
  RocketFill,
  FolderOpenFill,
  RobotFill,
  DatabaseFill,
  FacebookFill,
  HeartFill,
  BookFill,
  UnlockFill,
} from '@ant-design/icons-angular/icons';
import { PictureTwoTone } from '@ant-design/icons-angular/icons';

const outline: IconDefinition[] = [
  ArrowLeftOutline,
  QuestionOutline,
  MinusOutline,
  SaveOutline,
  TeamOutline,
  RotateRightOutline,
  ExpandOutline,
  CompressOutline,
  LinkOutline,
  ArrowRightOutline,
  MoreOutline,
  FileTextOutline,
  LoginOutline,
  LogoutOutline,
  MenuOutline,
  PlusOutline,
  EyeInvisibleOutline,
  EyeOutline,
  MailOutline,
  LockOutline,
  UserOutline,
  CreditCardOutline,
  GoogleOutline,
  SettingOutline,
  NotificationOutline,
  DeleteOutline,
  FileImageOutline,
  CloseOutline,
  SearchOutline,
  DownloadOutline,
  HeartOutline,
  BookOutline,
  ShareAltOutline,
  RocketOutline,
];
const fill: IconDefinition[] = [
  UnlockFill,
  EditFill,
  DeleteFill,
  LockFill,
  BulbFill,
  HomeFill,
  PlaySquareFill,
  ReadFill,
  ToolFill,
  RocketFill,
  FolderOpenFill,
  RobotFill,
  DatabaseFill,
  FacebookFill,
  HeartFill,
  BookFill,
];
const twoTone: IconDefinition[] = [PictureTwoTone];

export const icons: IconDefinition[] = [...outline, ...fill, ...twoTone];
