<section class="banner bg fx-center fx-col" style="padding: 0 20px">
  <h1 class="no-margin font-bold text-white">飞力博士加盟教室</h1>
  <p class="h5 no-margin font-bold text-white">
    孩子的全面素质教育，是我们教師的使命！
  </p>
</section>
<section>
  <div class="slogan-wrapper fx-center fx-col">
    <h3 style="padding: 0 30px">一站式STEAM素质教培加盟方案！</h3>
    <div
      class="slogan-container align-center fx-row justify-between"
      style="width: 100%"
    >
      <div class="slogan fx-row fx-center" *ngFor="let slogan of slogans">
        <div class="h3" [innerHtml]="slogan"></div>
      </div>
    </div>
  </div>
  <div class="split carousel-wrapper">
    <div class="start carousel">
      <picture>
        <source
          media="(max-width: 600px)"
          srcset="assets/images/page/plan/classroom/7.png"
        />
        <img
          class="img-fluid"
          src="assets/images/page/plan/classroom/7.png"
          alt=""
        />
      </picture>
    </div>
    <div class="end desc">
      <p class="h5 text-white">飞力博士IP导入教室装潢，提升企业品牌形象。</p>
    </div>
  </div>
</section>
<picture>
  <source
    media="(max-width: 600px)"
    srcset="assets/images/page/plan/classroom/2_mobile.jpg"
  />
  <img class="img-fluid" src="assets/images/page/plan/classroom/2.jpg" alt="" />
</picture>
<app-franchise-contact></app-franchise-contact>
<section>
  <div class="doctor-wrapper fx-col align-center">
    <picture>
      <source
        media="(max-width: 600px)"
        srcset="assets/images/page/plan/classroom/doctor_eye_mobile.png"
      />
      <img
        class="img-fluid title"
        src="assets/images/page/plan/classroom/doctor_eye.png"
        alt=""
      />
    </picture>
    <h2>教育核心&nbsp;‧&nbsp;国际品牌</h2>
    <div class="doctor-content fx-row">
      <div class="pic">
        <picture>
          <source
            media="(max-width: 600px)"
            srcset="assets/images/page/plan/classroom/8_mobile.jpg"
          />
          <img
            class="img-fluid"
            src="assets/images/page/plan/classroom/8.jpg"
            alt=""
          />
        </picture>
      </div>
      <div class="description">
        <h6>「飞力博士」Dr.Flip</h6>
        <p>
          结合翻转(Flip)和AI教育博士
          (Doctor)，是以K-12教育而生的STEAM国际教育品牌，集合国内外顶尖教育硕博士核心团队打造最前沿的STEAM课程，我们建构4岁到18岁完整教育体系，并且研发多项专利教育机器人、编程软件及AI学习软件，是由课程跨度到硬件、软件最完整的教育产品。
        </p>
        <h6>教研团队眼中的STEAM</h6>
        <p>
          是结合跨领域、生活议题、学科知识的素质课程，STEAM结合Science科学、Technology科技、Engineering工程、Art艺术、Mathematics数学，目的在于培养孩子们从生活、世界、环境等议题中，学习批判思考、创造能力、沟通合作与问题解决能力。
        </p>
      </div>
    </div>
  </div>
</section>
<picture>
  <source
    media="(max-width: 600px)"
    srcset="assets/images/page/plan/classroom/3_mobile.jpg"
  />
  <img class="img-fluid" src="assets/images/page/plan/classroom/3.jpg" alt="" />
</picture>
<picture>
  <source
    media="(max-width: 600px)"
    srcset="assets/images/page/plan/classroom/4_mobile.jpg"
  />
  <img class="img-fluid" src="assets/images/page/plan/classroom/4.jpg" alt="" />
</picture>
<picture>
  <source
    media="(max-width: 600px)"
    srcset="assets/images/page/plan/classroom/5_mobile.png"
  />
  <img class="img-fluid" src="assets/images/page/plan/classroom/5.png" alt="" />
</picture>
<picture>
  <source
    media="(max-width: 600px)"
    srcset="assets/images/page/plan/classroom/6_mobile.jpg"
  />
  <img class="img-fluid" src="assets/images/page/plan/classroom/6.jpg" alt="" />
</picture>
<app-franchise-contact></app-franchise-contact>
