import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { ProjectEditingComponent } from 'projects/fliprobot/src/app/project/project-editing/project-editing.component';
import { UrlTree, CanDeactivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SaveGuard implements CanDeactivate<ProjectEditingComponent> {
  constructor(private modal: NzModalService) {}
  canDeactivate(
    component: ProjectEditingComponent,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return component.saveGuard
      ? new Promise((resolve) => {
          this.modal.create({
            nzTitle: null,
            nzContent: '你的专题还没存储，确定要离开页面吗?',
            nzOnOk: () => resolve(true),
          });
        })
      : true;
  }
}
