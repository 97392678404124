<main class="fx-col fx-center" [ngClass]="isModal ? 'bg-white' : 'bg-light'">
  <div
    class="container fx-col fx-center"
    [style.min-height]="isModal ? '' : 'calc(100vh - 64px)'"
    style="gap: 15px"
  >
    <picture class="logo" *ngIf="!isModal">
      <img class="img-fluid" src="assets/images/logo.png" alt="" />
    </picture>
    <form nz-form [formGroup]="loginForm">
      <app-account
        [inputForm]="loginForm"
        [isLogin]="true"
        (accountTypeChange)="onAccountTypeChange($event)"
      ></app-account>
      <nz-form-item>
        <ng-template #lockIcon>
          <i nz-icon nzType="lock"></i>
        </ng-template>
        <ng-template #eyeIcon>
          <i
            nz-icon
            [nzType]="isPasswordVisible ? 'eye' : 'eye-invisible'"
            (click)="isPasswordVisible = !isPasswordVisible"
          ></i>
        </ng-template>
        <nz-form-control
          [nzErrorTip]="
            form.password.hasError('required')
              ? ('error.required'
                | translate: { value: ('auth.password' | translate) })
              : form.password.hasError('pattern')
              ? ('error.passwordFormat' | translate)
              : ('error.passwordLength' | translate)
          "
        >
          <nz-input-group
            [nzPrefix]="lockIcon"
            [nzSuffix]="eyeIcon"
            nzSize="large"
          >
            <input
              nz-input
              [placeholder]="'auth.password' | translate"
              [type]="isPasswordVisible ? 'text' : 'password'"
              formControlName="password"
              autocomplete
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
    <a (click)="onForgetPasswordBtnClink()">{{
      'auth.forgetPassword' | translate | titlecase
    }}</a>
    <button nz-button nzType="primary" (click)="submitLoginForm()">
      {{ 'auth.login' | translate | titlecase }}
    </button>
    <hr />
    <button nz-button class="social-login-btn">
      <img src="assets/icon/wechat.png" alt="wechat-icon" />
      <span>Wechat {{ 'auth.login' | translate | titlecase }}</span>
    </button>
    <button nz-button class="social-login-btn">
      <img src="assets/icon/QQ.png" alt="qq-icon" />
      <span>QQ {{ 'auth.login' | translate | titlecase }}</span>
    </button>
    <p>即将开放社群绑定系统，近请期待</p>
  </div>
</main>
