<footer>
  <div *ngIf="'Shared.navbar' | translate as nav" class="container">
    <nav
      [ngClass]="(isMobile$ | async) ? 'fx-col' : 'fx-row'"
      class="justify-between"
    >
      <ul>
        <picture>
          <img class="logo" src="assets/images/logo_white.png" alt="" />
        </picture>
        <hr *ngIf="isMobile$ | async" />
      </ul>
      <ul class="fx-col">
        <li class="space-item text-dark">{{ nav.about }}</li>
        <li class="space-item">
          <a routerLink="/about-us">{{ nav.about }}</a>
        </li>
        <li class="space-item">
          <a routerLink="/contact-us">{{ nav.contact }}</a>
        </li>
        <hr *ngIf="isMobile$ | async" />
      </ul>
      <ul class="fx-col">
        <li class="space-item text-dark">{{ nav.product.main }}</li>
        <li class="space-item">
          <a routerLink="/courses" [queryParams]="{ tab: 0 }">{{
            nav.product.introduction
          }}</a>
        </li>
        <!-- <li class="space-item">
          <a routerLink="/plan/classroom">
            {{ nav.product.classroom }}
          </a>
        </li> -->
        <li class="space-item">
          <a routerLink="/plan/school">
            {{ nav.product.school }}
          </a>
        </li>
        <hr *ngIf="isMobile$ | async" />
      </ul>
      <ul class="fx-col">
        <li class="space-item text-dark">{{ nav.player.main }}</li>
        <li class="space-item">
          <a routerLink="/player/projects"> {{ nav.player.project }} </a>
        </li>
        <hr *ngIf="isMobile$ | async" />
      </ul>
      <ul class="fx-col">
        <li class="space-item text-dark">{{ nav.certificate.main }}</li>
        <li class="space-item">
          <a routerLink="/certificate/teacher">{{ nav.certificate.teacher }}</a>
        </li>
        <li class="space-item">
          <a routerLink="/certificate/student">{{ nav.certificate.student }}</a>
        </li>
        <hr *ngIf="isMobile$ | async" />
      </ul>
      <ul class="fx-col">
        <li class="space-item text-dark">{{ nav.tools }}</li>
        <li class="space-item"><a routerLink="/tools">FlipCode</a></li>
        <li class="space-item"><a routerLink="/tools">FlipRAS</a></li>
        <li class="space-item"><a routerLink="/tools">FlipDuino</a></li>
        <li class="space-item"><a routerLink="/tools">FlipAIoT</a></li>
        <hr *ngIf="isMobile$ | async" />
      </ul>
      <ul class="fx-col">
        <li class="space-item text-dark">{{ nav.teaching.main }}</li>
        <li class="space-item">
          <a routerLink="/courses/preparation">
            {{ nav.teaching.main }}
          </a>
        </li>
        <hr *ngIf="isMobile$ | async" />
      </ul>

      <div
        class="fx-row"
        [ngClass]="(isMobile$ | async) ? 'align-self-center' : 'align-start'"
      >
        <ng-template #qrcode>
          <div
            class="fx-row"
            style="
              background-color: #23b6bc;
              border-radius: 15px;
              color: #fff;
              padding: 10px;
              margin: -12px 0;
            "
          >
            <picture>
              <img
                style="width: 100px; height: 100px; margin-right: 10px"
                src="/assets/images/page/home/actura-qrcode.jpg"
                alt=""
              />
            </picture>
            <div class="fx-col fx-center">
              <span>璞学至慧教育<br />Wechat公众号</span>
            </div>
          </div>
        </ng-template>
        <div class="icon-container fx-row fx-center">
          <a href="https://weibo.com/u/7528023944" target="_blank">
            <img src="assets/icon/weibo.png" alt="" />
          </a>
        </div>
        <div class="fx-col fx-center" style="max-width: 160px">
          <img
            class="img-fluid"
            src="assets/images/page/certificate/teacher/wechat_1.png"
            alt=""
            style="display: inline-block; margin-bottom: 10px"
          />
          <img
            class="img-fluid"
            src="assets/images/page/certificate/teacher/wechat_2.png"
            alt=""
          />
        </div>
      </div>
    </nav>
    <hr
      *ngIf="!(isMobile$ | async)"
      style="width: 100%; border-width: 0.5px; margin: 15px 0"
    />
    <div
      [ngClass]="
        (isMobile$ | async) ? 'fx-col align-center' : 'fx-row align-start'
      "
      class="justify-between"
    >
      <p
        [style.margin-bottom]="(isMobile$ | async) ? '10px' : '0px'"
        class="text-white"
      >
        Copyright &copy; 2020 Actura Co.,Ltd. All rights reserved.
      </p>
      <p class="no-margin">
        <a class="text-white" href="https://beian.miit.gov.cn/" target="_blank"
          >沪ICP备19028611号-3</a
        >
      </p>
      <div class="fx-col">
        <div class="fx-row legal">
          <a
            class="space-item"
            style="min-width: 60px"
            target="_blank"
            [href]="baseUrl + '/assets/static/privacy.html'"
            >{{ nav.privacy }}
          </a>
          <a
            class="space-item"
            style="min-width: 48px"
            target="_blank"
            [href]="baseUrl + '/assets/static/warranty.html'"
            >{{ nav.warranty }}
          </a>
          <a
            class="space-item"
            style="min-width: 48px"
            target="_blank"
            [href]="baseUrl + '/assets/static/site.html'"
            >{{ nav.use_terms }}
          </a>
        </div>
        <p
          class="text-white"
          [style.margin-bottom]="(isMobile$ | async) ? '10px' : '0px'"
        >
          联络地址：上海市徐汇区天钥桥路580号6楼
        </p>
      </div>
    </div>
  </div>
</footer>
