import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-student-certificate',
  templateUrl: './student-certificate.component.html',
  styleUrls: ['./student-certificate.component.less'],
})
export class StudentCertificateComponent implements OnInit {
  slogans: string[] = [
    '国际评鉴<br>累积成就',
    '多元学习<br>创造竞争力',
    'AI数据分析<br>专家检验',
    '素质提升<br>丰富履历',
  ];
  features: { title: string; desc: string }[] = [
    {
      title: '全球专家评鉴',
      desc: '目前全球教育发展的重心，正朝向素质教育的方向前进，但因为素质能力难以评鉴，因此一直是教育学术界积极研究的课题。 FlipRobot结合全球教育专家，打造素质能力评鉴系统，正是素质教育重要的一环。',
    },
    {
      title: 'AI大数据分析',
      desc: '我们的评鉴系统累积全球教师的评分数据，并透过AI人工智能搭建分析模型，能够修正教师个别评分时发生的主观问题，提出更具公信力的分析数据。',
    },
    {
      title: '完整学习履历',
      desc: '依照我们的学程体系，我们针对孩子成长的不同阶段打造学习指标，因此学习的期间越长，能力数据累积的更多，对于孩子的能力诊断将具备更深入的分析力。',
    },
    {
      title: '学习证书成绩',
      desc: '我们提供学生完成主题学习后获得成绩证书，能够呈现该主题的学习能力分析。成绩会依照全球大数据影响而波动，提供家长及时透过我们的平台了解孩子能力数据的变化。此外针对个别学生需求，可以成为适合申请国外大学的学习证书。',
    },
    {
      title: '职场核心竞争力',
      desc: '当孩子进入我们的体系学习课程，累积的数据将成为最具公信力的学习履历纪录，成为申请国外大学，甚或未来投入职场最具优势的能力成长纪录。',
    },
  ];
  constructor() {}

  ngOnInit() {}
}
