<div class="fx-row fx-wrap">
  <nz-select
    nzSize="large"
    [(ngModel)]="accountType"
    (ngModelChange)="onAccountTypeChange($event)"
    [nzDisabled]="!isBtnActive"
  >
    <nz-option nzLabel="+86 中国" nzValue="phone"></nz-option>
    <nz-option
      [nzLabel]="
        ('auth.email' | translate | titlecase) + ('auth.signup' | translate)
      "
      nzValue="email"
    ></nz-option>
  </nz-select>
  <div [formGroup]="inputForm" style="flex-basis: 65%">
    <nz-form-item>
      <nz-form-control
        [nzErrorTip]="
          form.account.hasError('pattern')
            ? ('error.phoneFormat' | translate)
            : form.account.hasError('email')
            ? ('error.emailFormat' | translate)
            : ((isPhoneType ? 'auth.enterPhoneNumber' : 'auth.enterEmail')
              | translate)
        "
      >
        <input
          type="text"
          nz-input
          formControlName="account"
          [maxlength]="isPhoneType ? 11 : 254"
          [placeholder]="
            (isPhoneType ? 'auth.enterPhoneNumber' : 'auth.enterEmail')
              | translate
          "
          autocomplete
        />
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
<div [formGroup]="inputForm" *ngIf="!isLogin">
  <nz-form-item>
    <nz-form-control
      [nzErrorTip]="
        form.verificationCode.hasError('pattern')
          ? ('error.verificationCodeFormat' | translate)
          : ('error.required'
            | translate: { value: ('auth.verificationCode' | translate) })
      "
      [nzExtra]="'auth.verificationTip' | translate"
    >
      <input
        type="text"
        nz-input
        formControlName="verificationCode"
        minlength="6"
        maxlength="6"
        placeholder="------"
        style="text-align: center; width: 35%"
      />
      <button
        class="send-code-btn"
        [disabled]="!isBtnActive"
        #sendCodeBtn
        (click)="onCodeBtnClick()"
      >
        <span>{{ 'auth.sendVerificationCode' | translate }}</span>
        <span *ngIf="!isBtnActive" style="margin-left: 10px">{{ time }} s</span>
      </button>
    </nz-form-control>
  </nz-form-item>
</div>
