<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="null"
  [nzFooter]="null"
  [nzWidth]="400"
  (nzOnCancel)="onModalCancel()"
>
  <div class="fx-col fx-center">
    <button
      nz-button
      class="no-radius no-border align-self-start"
      *ngIf="isResetPassword"
      (click)="isResetPassword = false"
    >
      <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
    </button>
    <picture>
      <img
        class="img-fluid"
        src="assets/images/logo.png"
        alt=""
        style="max-width: 180px"
      />
    </picture>
    <nz-tabset
      [nzAnimated]="false"
      [nzTabBarStyle]="{
        display: 'flex',
        margin: '10px',
        'justify-content': 'center',
        'border-color': '#23b6bc'
      }"
      nzSize="large"
    >
      <nz-tab [nzTitle]="loginTabBtn" *ngIf="!isResetPassword">
        <ng-template nz-tab>
          <app-login
            [isModal]="true"
            (resetPassword)="isResetPassword = true"
            (closeModel)="onModalCancel()"
          ></app-login>
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="signupTabBtn" *ngIf="!isResetPassword">
        <ng-template nz-tab>
          <app-signup
            [isModal]="true"
            (closeModel)="onModalCancel()"
          ></app-signup>
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="resetPasswordTabBtn" *ngIf="isResetPassword">
        <ng-template nz-tab>
          <app-reset-password
            [isModal]="true"
            (resetPassword)="isResetPassword = false"
          ></app-reset-password>
        </ng-template>
      </nz-tab>
      <ng-template #loginTabBtn>
        <button nz-button class="tab-btn" nzType="link">
          {{ 'auth.login' | translate }}
        </button>
      </ng-template>
      <ng-template #signupTabBtn>
        <button nz-button class="tab-btn" nzType="link">
          {{ 'auth.signup' | translate }}
        </button>
      </ng-template>
      <ng-template #resetPasswordTabBtn>
        <button nz-button class="tab-btn" nzType="link">
          {{ 'auth.resetPassword' | translate }}
        </button>
      </ng-template>
    </nz-tabset>
  </div>
</nz-modal>
