import { WebShareDirective } from './directives/web-share.directive';
import { NgModule } from '@angular/core';
import {
  CardPipe,
  CountryPipe,
  LangPipe,
  SafePipe,
  SteamPipe,
  SubjectPipe,
  VideoPipe,
  ActionPipe,
} from './pipes';

const pipes = [
  SteamPipe,
  SafePipe,
  LangPipe,
  CardPipe,
  CountryPipe,
  SubjectPipe,
  VideoPipe,
  ActionPipe,
];
const directives = [WebShareDirective];

@NgModule({
  declarations: [pipes, directives],
  exports: [pipes, directives],
  providers: [...pipes]
})
export class ToolsModule {}
