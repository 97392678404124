import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CountryMap } from '../pipes/country.pipe';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  language$ = new ReplaySubject<LangChangeEvent>(1);
  translate = this.translateService;
  constructor(private translateService: TranslateService) {}
  setInitState() {
    this.translateService.addLangs(['en', 'zh-cn']);
    const lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : this.translate.getBrowserLang().includes('zh')
      ? 'zh-cn'
      : 'en';
    this.setLang(lang);
  }
  setLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.translateService.onLangChange
      .pipe(take(1))
      .subscribe((result) => this.language$.next(result));
    this.translateService.use(lang);
  }
}
