import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styles: [
    `
      nz-input-group {
        min-height: 40px;
      }
      .anticon {
        line-height: 32px;
      }
    `,
  ],
})
export class NewPasswordComponent implements OnInit {
  @Input() inputForm: FormGroup;
  isPasswordVisible = false;
  constructor() {}

  ngOnInit(): void {}

  get form() {
    return this.inputForm.controls;
  }
}
