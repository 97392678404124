<main>
  <section class="banner fx-col fx-center">
    <picture>
      <img class="img-fluid" src="assets/images/page/tools/logo.png" />
    </picture>
  </section>
  <section class="bg-light">
    <div class="container">
      <section
        class="bg-white section"
        *ngFor="let tool of 'Fliptool.tools' | translate; let i = index"
      >
        <div class="fx-row fx-wrap justify-between">
          <div
            class="fx-col"
            [style.flex-basis]="(isWeb$ | async) ? '75%' : '100%'"
          >
            <h4 class="font-bold text-primary">{{ tool.heading }}</h4>
            <div [innerHTML]="tool.paragraph | safe: 'html'"></div>
          </div>
          <div
            [ngClass]="
              (isWeb$ | async)
                ? 'fx-col align-center'
                : 'fx-row justify-between'
            "
            [style.flex-basis]="(isWeb$ | async) ? '25%' : '100%'"
          >
            <picture>
              <img class="img-fluid" [src]="tool.icon" />
            </picture>
            <div class="fx-col">
              <ng-container *ngFor="let btn of downloads[i]">
                <ng-container
                  *ngIf="btn.checkDownloadAccess && isLogin; else defaultBtn"
                >
                  <button
                    class="btn btn-outline-primary space-item"
                    (click)="open(downloadUrl)"
                    [disabled]="!(downloadAccess$ | async)"
                  >
                    {{ btn.name | translate }}
                  </button>
                </ng-container>
                <ng-template #defaultBtn>
                  <button
                    class="btn btn-outline-primary space-item"
                    (click)="
                      open(
                        btn.hasAccessToken && isLogin
                          ? btn.href + '?accessToken=' + accessToken
                          : btn.href
                      )
                    "
                    [disabled]="btn.isDisabled"
                  >
                    {{ btn.name | translate }}
                  </button>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</main>
<app-footer></app-footer>
