<main>
  <div class="banner bg fx-col fx-center">
    <div class="container fx-col">
      <h2
        class="text-primary"
        [ngClass]="(isMobile$ | async) ? 'text-center' : 'text-start'"
      >
        {{ 'Contact.heading' | translate }}
      </h2>
      <p class="h6">{{ 'Contact.paragraph' | translate }}</p>
      <div [ngClass]="(isMobile$ | async) ? 'fx-col' : 'fx-row'">
        <a
          class="btn btn-outline-secondary space-item"
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=aiSugBlsa0aDhkLBb5MdtVcVW6Tef6pDnPeztYb23wJUN0dEVkY2NjJaQUhEM0xaVDBYWVNGTFJLViQlQCN0PWcu"
          target="_blank"
        >
          <i nz-icon nzType="file-text"></i>{{ 'Contact.business' | translate }}
        </a>
        <a
          class="btn btn-outline-secondary space-item"
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=aiSugBlsa0aDhkLBb5MdtVcVW6Tef6pDnPeztYb23wJUM1lXNjMzVzdWVVU4RVRYMDg5WFZPMjZIVSQlQCN0PWcu"
          target="_blank"
        >
          <i nz-icon nzType="file-text"></i>{{ 'Contact.support' | translate }}
        </a>
      </div>
      <p>联络地址：上海市徐汇区天钥桥路580号6楼</p>
    </div>
  </div>
</main>
<app-footer></app-footer>
