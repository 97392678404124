import { NzMessageService } from 'ng-zorro-antd/message';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'projects/tools/src/lib/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
    `
      .container {
        max-width: 360px;
        padding: 15px;
      }
      .logo {
        max-width: 180px;
      }
      .ant-form {
        width: 330px;
      }
      .ant-btn {
        font-size: 16px;
        height: 40px;
        width: 100%;
      }
      a {
        color: #23b6bc;
        align-self: flex-end;
        margin-top: -24px;
      }
      hr {
        background-color: #23b6bc;
        border: 0;
        height: 1px;
        width: 100%;
      }
      .social-login-btn {
        background-color: transparent;
        border-radius: 5px;
      }
      .social-login-btn img {
        display: inline-block;
        height: 25px;
        margin-right: 20px;
      }
    `,
  ],
})
export class LoginComponent implements OnInit {
  @Input() isModal: boolean;
  @Output() resetPassword: EventEmitter<any> = new EventEmitter();
  @Output() closeModel = new EventEmitter<any>();
  loginForm: FormGroup;
  isPasswordVisible = false;
  isLoading = false;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private message: NzMessageService,
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      account: new FormControl(null, { updateOn: 'blur' }),
      password: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          Validators.pattern('[a-zA-Z0-9]*'),
        ],
        updateOn: 'blur',
      }),
    });
  }
  get form() {
    return this.loginForm.controls;
  }
  onAccountTypeChange(type: string) {
    if (type === 'email') {
      this.form.account.setValidators([Validators.required, Validators.email]);
      this.form.account.updateValueAndValidity();
    } else {
      this.form.account.setValidators([
        Validators.required,
        Validators.pattern(
          /^((13[0-9])|(14[5,7,9])|(15([0-3]|[5-9]))|(16[5,6])|(17[0-8])|(18[0-9])|(19[1、5、8、9]))\d{8}$/,
        ),
      ]);
      this.form.account.updateValueAndValidity();
    }
  }
  checkFormValue(): void {
    for (const i of Object.keys(this.loginForm.controls)) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }
  }
  submitLoginForm(): void {
    this.checkFormValue();
    if (!this.loginForm.invalid) {
      this.isLoading = true;
      this.loginForm.disable();
      this.auth
        .login(this.loginForm.getRawValue())
        // .pipe(
        //   concatMap(() =>
        //     location.pathname === '/player/projects'
        //       ? this.dataSource.loadProjects()
        //       : of(''),
        //   ),
        // )
        .subscribe(
          () => {
            this.message.info('登入成功');
            if (this.isModal) {
              this.loginForm.enable();
              this.loginForm.reset();
              this.closeModel.emit();
            } else {
              this.router.navigateByUrl('/');
            }
          },
          () => {
            this.loginForm.enable();
            this.isLoading = false;
          },
        );
    }
  }
  onForgetPasswordBtnClink(): void {
    if (this.isModal) {
      this.resetPassword.emit();
    } else {
      this.router.navigateByUrl('/auth/reset-password');
    }
  }
}
