import { Component } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { LangService } from 'projects/tools/src/lib/services';
import { Meta, Title } from '@angular/platform-browser';
import { MetaData } from './shared/data/page';
import { Router, NavigationEnd } from '@angular/router';

interface IPageMeta {
  path: string;
  title: string;
  meta?: {
    description?: string;
  };
}
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styles: [],
})
export class AppComponent {
  pages: Array<IPageMeta> = MetaData;
  constructor(
    private language: LangService,
    private meta: Meta,
    private router: Router,
    private title: Title,
  ) {

    this.language.setInitState();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.pages.find((page) => page.path === location.pathname) || {}),
        tap((page: IPageMeta) => {
          this.title.setTitle(page.title ? `${page.title} - FlipRobot` : 'FlipRobot');
          if (page.hasOwnProperty('meta')) {
            this.meta.updateTag({
              name: 'description',
              content: page.meta.description || '',
            });
          } else {
            this.meta.removeTag('name="description"');
          }
        }),
      )
      .subscribe(() => window.scrollTo({ top: 0, left: 0 }));
  }
}
