import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-franchise-contact',
  templateUrl: './franchise-contact.component.html',
  styleUrls: ['./franchise-contact.component.less']
})
export class FranchiseContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
