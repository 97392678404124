<nz-affix>
  <header class="navbar-header" [style.background-color]="navbarBgColor">
    <div [ngClass]="(isWeb$ | async) ? 'container' : ''">
      <div class="fx-row fx-center justify-between">
        <a class="navbar-brand" routerLink="/">
          <picture>
            <img class="img-fluid" [src]="navbarBrandImg" />
          </picture>
        </a>
        <nav class="navbar-nav">
          <ul class="align-center fx-row no-margin no-padding">
            <ng-container *ngIf="isWeb$ | async">
              <ng-container *ngIf="isLogin$ | async">
                <li
                  *ngIf="isManager$ | async"
                  class="align-center fx-row nav-item"
                  [ngClass]="!(isWeb$ | async) ? '' : 'space-item'"
                >
                  <a
                    [routerLink]="['/admin']"
                    [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'"
                  >
                    Admin
                  </a>
                </li>
              </ng-container>
              <li class="align-center fx-row nav-item space-item">
                <div
                  *ngTemplateOutlet="
                    navbarItem;
                    context: { navItem: navItems.home }
                  "
                ></div>
              </li>
              <li
                class="nav-item space-item"
                nz-dropdown
                [nzDropdownMenu]="productDropdown"
                [nzPlacement]="'bottomCenter'"
              >
                <a [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'">
                  {{ 'Shared.navbar.product.main' | translate }}
                </a>
              </li>
              <li class="align-center fx-row nav-item space-item">
                <div
                  *ngTemplateOutlet="
                    navbarItem;
                    context: { navItem: navItems.project }
                  "
                ></div>
              </li>
              <li
                class="nav-item space-item"
                nz-dropdown
                [nzDropdownMenu]="certificateDropdown"
                [nzPlacement]="'bottomCenter'"
              >
                <a [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'">
                  {{ 'Shared.navbar.certificate.main' | translate }}
                </a>
              </li>
              <li class="align-center fx-row nav-item space-item">
                <div
                  *ngTemplateOutlet="
                    navbarItem;
                    context: { navItem: navItems.tool }
                  "
                ></div>
              </li>
              <li
                class="nav-item fx-row align-center"
                [ngClass]="!(isWeb$ | async) ? '' : 'space-item'"
                (click)="onMenuItemClick()"
              >
                <a
                  [routerLink]="
                    pathname.includes('/courses/preparation')
                      ? pathname
                      : ['/courses/preparation']
                  "
                  [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'"
                >
                  <i
                    *ngIf="!(isWeb$ | async)"
                    nz-icon
                    nzTheme="fill"
                    [nzType]="'folder-open'"
                  ></i>
                  {{ 'Shared.navbar.teaching.main' | translate }}
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="isLogin$ | async; else SignIn">
              <li
                class="nav-item space-item"
                nz-dropdown
                [nzDropdownMenu]="userDropdown"
                [nzPlacement]="'bottomCenter'"
              >
                <nz-avatar
                  [nzSize]="32"
                  [nzSrc]="(avatar$ | async) || 'assets/images/user.png'"
                  class="bg-primary no-margin"
                ></nz-avatar>
              </li>
            </ng-container>
            <ng-template #SignIn>
              <li class="nav-item space-item no-border">
                <button
                  class="bg-primary text-white fx-row fx-center no-border"
                  style="
                    padding: 6px 15px;
                    border-radius: 3px;
                    line-height: 24px;
                  "
                  [disabled]="isAuthPath"
                  (click)="isModalVisible = true"
                >
                  {{ 'Shared.navbar.sign_in' | translate }}
                </button>
              </li>
            </ng-template>
            <li>
              <button
                nz-button
                nzType="link"
                [style.color]="
                  currentLanguage() === 'zh-cn' ? '#23b6bc' : navbarFontColor
                "
                (click)="useLanguage('zh-cn')"
                style="padding: 0 5px"
              >
                简中
              </button>
              <span [style.color]="navbarFontColor">|</span>
              <button
                nz-button
                nzType="link"
                [style.color]="
                  currentLanguage() === 'en' ? '#23b6bc' : navbarFontColor
                "
                (click)="useLanguage('en')"
                style="padding: 0 5px"
              >
                EN
              </button>
            </li>
            <li *ngIf="!(isWeb$ | async)">
              <button
                nz-button
                nzType="link"
                nz-dropdown
                [nzDropdownMenu]="mobileMenu"
                [nzOverlayStyle]="mobileDropdownStyle"
                [nzPlacement]="'bottomRight'"
                [(nzVisible)]="isMenuVisible"
                (click)="onMenuItemClick()"
                style="width: 50px"
              >
                <i
                  class="no-margin"
                  [style.color]="navbarFontColor"
                  nz-icon
                  nzType="menu"
                ></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</nz-affix>
<nz-dropdown-menu #certificateDropdown="nzDropdownMenu">
  <ul nz-menu nzMode="horizontal" [nzSelectable]="false">
    <li
      *ngFor="let menuItem of navItems.certificate"
      class="no-padding"
      nz-menu-item
    >
      <a
        *ngIf="menuItem.link"
        [routerLink]="[menuItem.link]"
        [queryParams]="menuItem.query"
        class="no-margin"
        style="
          width: 100%;
          height: 100%;
          padding: 10px 15px;
          text-align: center;
        "
      >
        {{ 'Shared.navbar.' + menuItem.label | translate }}
      </a>
    </li>
  </ul>
</nz-dropdown-menu>
<nz-dropdown-menu #productDropdown="nzDropdownMenu">
  <ul nz-menu nzMode="horizontal" [nzSelectable]="false">
    <li
      *ngFor="let menuItem of navItems.product"
      class="no-padding"
      nz-menu-item
    >
      <a
        *ngIf="menuItem.link"
        [routerLink]="[menuItem.link]"
        [queryParams]="menuItem.query"
        class="no-margin"
        style="
          width: 100%;
          height: 100%;
          padding: 10px 15px;
          text-align: center;
        "
      >
        {{ 'Shared.navbar.' + menuItem.label | translate }}
      </a>
    </li>
  </ul>
</nz-dropdown-menu>
<ng-template #navbarItem let-navItem="navItem">
  <a
    [routerLink]="[navItem.routerLink]"
    [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'"
  >
    <i
      *ngIf="!(isWeb$ | async)"
      nz-icon
      nzTheme="fill"
      [nzType]="navItem.icon"
    ></i>
    {{ 'Shared.navbar.' + navItem.label | translate }}
  </a>
</ng-template>
<nz-dropdown-menu #mobileMenu="nzDropdownMenu">
  <ul class="navbar-nav" nz-menu nzMode="inline">
    <li class="nav-item no-border" nz-menu-item (click)="onMenuItemClick()">
      <div
        *ngTemplateOutlet="navbarItem; context: { navItem: navItems.home }"
      ></div>
    </li>
    <li class="nav-item no-border" nz-menu-item>
      <a>
        <i nz-icon nzTheme="fill" nzType="read"></i>
        {{ 'Shared.navbar.product.main' | translate }}
      </a>
    </li>
    <li
      class="nav-item no-border"
      nz-menu-item
      (click)="onMenuItemClick()"
      *ngFor="let menuItem of navItems.product"
    >
      <a
        [queryParams]="menuItem.query"
        [routerLink]="[menuItem.link]"
        style="margin-left: 12px; color: #000"
      >
        -&emsp;{{ 'Shared.navbar.' + menuItem.label | translate }}
      </a>
    </li>
    <li class="nav-item no-border" nz-menu-item (click)="onMenuItemClick()">
      <div
        *ngTemplateOutlet="navbarItem; context: { navItem: navItems.project }"
      ></div>
    </li>
    <li class="nav-item no-border" nz-menu-item>
      <a>
        <i nz-icon nzTheme="fill" nzType="play-square"></i>
        {{ 'Shared.navbar.certificate.main' | translate }}
      </a>
    </li>
    <li
      class="nav-item no-border"
      nz-menu-item
      (click)="onMenuItemClick()"
      *ngFor="let menuItem of navItems.certificate"
    >
      <a
        [queryParams]="menuItem.query"
        [routerLink]="[menuItem.link]"
        style="margin-left: 12px; color: #000"
      >
        -&emsp;{{ 'Shared.navbar.' + menuItem.label | translate }}
      </a>
    </li>
    <li class="nav-item no-border" nz-menu-item (click)="onMenuItemClick()">
      <div
        *ngTemplateOutlet="navbarItem; context: { navItem: navItems.tool }"
      ></div>
    </li>
    <li class="nav-item no-border" nz-menu-item (click)="onMenuItemClick()">
      <a
        [routerLink]="
          pathname.includes('/courses/preparation')
            ? pathname
            : ['/courses/preparation']
        "
        [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'"
      >
        <i
          *ngIf="!(isWeb$ | async)"
          nz-icon
          nzTheme="fill"
          [nzType]="'folder-open'"
        ></i>
        {{ 'Shared.navbar.teaching.main' | translate }}
      </a>
    </li>
    <ng-container *ngIf="isLogin$ | async">
      <hr />
      <li
        *ngFor="let menuItem of navItems.user"
        class="no-padding"
        nz-menu-item
      >
        <a
          *ngIf="menuItem.link"
          [routerLink]="[menuItem.link]"
          class="no-margin"
          style="width: 100%; height: 100%; padding: 9px 24px"
        >
          <i [nzType]="menuItem.icon" nz-icon style="margin-right: 10px"></i>
          {{ 'Shared.navbar.' + menuItem.label | translate }}
        </a>
      </li>
      <hr />
      <li class="no-padding" nz-menu-item>
        <a
          class="no-margin"
          style="width: 100%; height: 100%; padding: 9px 24px"
          (click)="logout()"
        >
          <strong>
            <i nzType="logout" nz-icon style="margin-right: 10px"></i>
            {{ 'Shared.navbar.sign_out' | translate }}
          </strong>
        </a>
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>
<nz-dropdown-menu #userDropdown="nzDropdownMenu">
  <ul nz-menu nzMode="horizontal" [nzSelectable]="false">
    <li *ngFor="let menuItem of navItems.user" class="no-padding" nz-menu-item>
      <a
        *ngIf="menuItem.link"
        [routerLink]="[menuItem.link]"
        class="no-margin"
        style="width: 100%; height: 100%; padding: 10px 15px"
      >
        <i [nzType]="menuItem.icon" nz-icon style="margin-right: 10px"></i>
        {{ 'Shared.navbar.' + menuItem.label | translate }}
      </a>
    </li>
    <hr style="margin: 8px 15px" />
    <li class="no-padding" nz-menu-item style="width: 170px; height: 40px">
      <a
        (click)="logout()"
        class="no-margin"
        style="width: 100%; height: 100%; padding: 10px 15px"
      >
        <strong>
          <i nzType="logout" nz-icon style="margin-right: 10px"></i>
          {{ 'Shared.navbar.sign_out' | translate }}
        </strong>
      </a>
    </li>
  </ul>
</nz-dropdown-menu>
<app-login-modal
  [isVisible]="isModalVisible"
  (modalCancel)="isModalVisible = false"
></app-login-modal>
<router-outlet (activate)="onActivate($event)"></router-outlet>
