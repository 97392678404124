import { AntdModule } from 'projects/tools/src/lib/antd.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CardGroupComponent } from './components/card-group/card-group.component';
import { CardInputComponent } from './components/card-input/card-input.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RedirectToBlogComponent } from './components/redirect-to-blog/redirect-to-blog.component';

@NgModule({
  declarations: [
    CardGroupComponent,
    CardInputComponent,
    NotFoundComponent,
    RedirectToBlogComponent,
  ],
  imports: [CommonModule, AntdModule, RouterModule, TranslateModule],
  exports: [
    CardGroupComponent,
    CardInputComponent,
    NotFoundComponent,
    RedirectToBlogComponent,
  ],
})
export class SharedModule {}
