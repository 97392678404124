import { RwdService } from 'projects/tools/src/lib/services';
import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/fliprobot/src/environments/environment';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit {
  baseUrl = environment.baseUrl;
  isMobile$: Observable<boolean>;
  constructor(private rwd: RwdService) {}

  ngOnInit(): void {
    this.isMobile$ = this.rwd.isMobile.pipe(shareReplay(1));
  }
}
