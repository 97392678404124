import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RwdService } from 'projects/tools/src/lib/services';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-teacher-certificate',
  templateUrl: './teacher-certificate.component.html',
  styleUrls: ['./teacher-certificate.component.less'],
})
export class TeacherCertificateComponent implements OnInit {
  isMobile$: Observable<boolean>;
  path = 'assets/images/teacher/training';
  hoverIndex = -1;
  advantages: string[] = [
    `区域派师<br />机构任课`,
    `知识累積<br />收入提升`,
    `素质教育<br />领先趋势`,
    `自我挑战<br />等級评鉴`,
  ];
  assessments: { title: string; desc: string }[] = [
    {
      title: '学程部分',
      desc: '教师必须熟悉FlipRobot学程体系，包含课程的架构及教育指标。',
    },
    {
      title: '课程部份',
      desc: '教师必须对官方教学手册精熟，并能拥有良好的教学方法、教学策略实施课程。',
    },
    {
      title: '编程部分',
      desc: '教师必须熟悉教学主题所有的编程习题，并能够清晰地进行教学。',
    },
    {
      title: '学具部分',
      desc: '教师必须熟悉教学主题使用的机器人，包括FlipBrain、DC Motor、机构、螺丝等零组件，并能够清晰地进行教学。',
    },
    {
      title: '评鉴部分',
      desc: '能够精熟FlipRobot发展的素质评鉴系统，并对于评测指标熟悉，尤其能够落实具鉴别度的评分。',
    },
    {
      title: '试教部分',
      desc: '我们重视教师教学教法，在教学中如何运用PBL教学模式，并能够清楚传达知识点，与课程中整体氛围营造良好',
    },
  ];
  constructor(private rwd: RwdService) {}

  ngOnInit() {
    this.isMobile$ = this.rwd.isMobile.pipe(shareReplay(1));
  }
  onMouseEnter(index: number) {
    this.hoverIndex = index;
  }
  onMouseLeave() {
    this.hoverIndex = -1;
  }
  getImgSrc(index: number) {
    return `assets/images/page/certificate/teacher/5_${index + 1}${
      this.hoverIndex === index ? '_hover' : ''
    }.png`;
  }
}
